import { mergeProps, type Component, type ParentProps } from "solid-js";
import { Dynamic } from "solid-js/web";

import { css, cx } from "#style/css";
import { SystemProperties } from "#style/types";

type TypographyProps = ParentProps<{
  tag?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "a"
    | "span"
    | "dd"
    | "dt"
    | "th"
    | "td"
    | "li";
  textStyle?: SystemProperties["textStyle"]; // From Panda-CSS theme
  class?: string;
  ref?: HTMLElement;
}>;

export const Typography: Component<TypographyProps> = (_props) => {
  const props = mergeProps({ tag: "p", textStyle: "body" }, _props);

  return (
    <Dynamic
      ref={props.ref}
      component={props.tag}
      class={cx(props.class, css({ textStyle: props.textStyle }))}
    >
      {props.children}
    </Dynamic>
  );
};
